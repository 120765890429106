$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.breadcrumb {
  color: map-get($colors, breadcrumb);
  font-size: rem(12);

  .cmp-breadcrumb__list {
    margin: 0;
    padding: 1em 0;
  }

  li {
    &:not(:last-child) {
      font-weight: 600;

      &::after {
        content: '/';
        padding: 0 0.5em;
      }
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
}
